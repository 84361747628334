/**
 * @file Contact.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main contact page component.
 */

import React, {useEffect, useState} from 'react';
import ContactPageFooter from './ContactPageFooter'
import './contact.css'
import officePhoto from '../../images/officephoto.jpg'
import { Waypoint } from 'react-waypoint';
import Fade from 'react-reveal/Fade';

function Contact() { 
  document.title = "Kontakt - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    useEffect(() => {
        function handleResize() {
                const  navbarHeight = document.querySelector('.navbar').offsetHeight;
                let heading = document.querySelector('.top-heading');
                if (heading) {
                    heading.style.paddingTop = navbarHeight +'px';
                }
                
                let line = document.querySelector('.footer-text-hr').offsetWidth;
                let map = document.querySelector('.google-map');
                map.style.width = line + 'px';
                map.style.height = line/2 + 'px';
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
      <div className="contact-container">
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
        <h3 className="top-heading"></h3>
        <img src={officePhoto} className="contact-office-img img-fluid"></img>
        
        <h4 className="contact-heading">Adresa</h4>
        <hr className="footer-text-hr"/> 
        <p>Bajkalská 30<br/>821 05 Bratislava - Ružinov<br/>IČO: 44789670<br/>IČ DPH: SK2022842195</p>

        <h4 className="contact-heading">Kontakt</h4>
        <hr className="footer-text-hr"/> 
        <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone footer-icon" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            +421 911 432 677

            <br/>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope footer-icon" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            office@kyska.sk
        </p>
        
        <h4 className="contact-heading">Web</h4>
        <hr className="footer-text-hr"/> 
        <p>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }} width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
            </svg>
            Martin Kubička
            <br/>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope footer-icon" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            martinkubicka22@gmail.com
        </p>

        <h4 className="contact-heading">Tu nás nájdete</h4>
        <hr className="footer-text-hr"/> 

        <iframe className="google-map" id="gmap_canvas" src="https://maps.google.com/maps?q=Kyska%20architects%20bratislava&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" style={{ filter: "grayscale(100%)" }}></iframe>
        </Fade>
        </Waypoint>

        <ContactPageFooter />

      </div>
    );
  }
  

  export default Contact;

/*** End of Contact.js ***/
