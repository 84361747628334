/**
 * @file Team.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main team page component.
 */

import React, {useState, useEffect} from 'react';
import Footer from '../../shared/Footer';
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import './team.css';
import Portrait from "../../images/portrait.jpg"

function Team() { 
    document.title = "Náš team - kyska.sk";

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    useEffect(() => {
        function handleResize() {
                const  navbarHeight = document.querySelector('.navbar').offsetHeight;
                let heading = document.querySelector('.top-heading');
                if (heading) {
                    heading.style.paddingTop = navbarHeight +'px';
                }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
      <div>
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
    
            <h3 className="top-heading"></h3>

            <h4 className="team-heading">Prinášame riešenia a dizajn</h4>
            <p>Sme presvedčení, že dobré veci vznikajú len v dobrej pracovnej a ľudskej atmosfére. Všetky projekty vnímame ako výzvu. Sme „family friendly“, máme radi svojich blízkych, priateľov a zaujímavých ľudí, estetiku všedného dňa, faunu, flóru a celé universum.</p>
            
            <hr className="team-text-hr"/> 

            <div className="row">
                <div className="card team-card">
                    <div class="row card-body">
                    <img class="col-sm-6 team-img-portrait"  src={Portrait}/>
                       <div class="col-sm-6">
                            <h5 class="card-title team-card-heading">Ing.arch. Martin Kýška</h5>
                            <h5 class="card-title team-card-birth">*1968  Bratislava</h5>
                            
                            <p className="card-text team-card-text">Autorizovaný architekt Slovenskej komory architektov 0471 AA 1004.
                            
                            <br />
                            <br />
                            Vzdelanie:
                            <hr className="team-portrait-hr"/> 
                            1987 - 1993   štúdium architektúry  na  STU – Bratislava
                            <br />
                            1989   výmenný pobyt na TU - Weimar
                            <br />
                            1990   výmenný pobyt na TU - Wien

                            <br />
                            <br />
                            Prax:
                            <hr className="team-portrait-hr"/> 
                            1993 - 1997	architektonická kancelária Flavio THONET- Salzburg
                            <br />
                            1997 - 2002	architekt Prof. Dr. h. c. DI. Peter Czernin, Wien 
                            <br />
                            2002 - 2006	architekt  Prof. DI. Heinz Neumann & Partners, Wien
                            <br />
                            2006 - 2007 	architektonická kancelária  AT- P Wien
                            <br />
                            2007 - 2009	konateľ, LENGGER Slovakia s.r.o
                            <br />
                            2009 -		založenie KYSKA. architects s.r.o.
                           </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="team-others">
                <div class="row">
                    <div class="col">
                        <h5 className="team-others-heading team-others-heading-left-col">Team</h5>
                        <hr className="team-left-col"/> 
                        <p className='team-others-p team-others-p-left-col'>
                            Ing.arch. Peter Hromják
                            <br />
                            <p class="team-others-p team-others-p-birth">
                                *1982 Prešov
                            <br/>
                            štúdium architektúry na STU – Bratislava
                            </p>

                            <div class="team-others-padding">
                                Ing.arch. Roman Kutiš
                                <br />
                                <p class="team-others-p team-others-p-birth">
                                    *1966 Topoľčany
                                <br/>
                                štúdium architektúry na STU – Bratislava
                                </p>
                            </div>

                            <div class="team-others-padding">
                                Ing.arch. Dalibor Branderský
                                <br />
                                <p class="team-others-p team-others-p-birth">
                                    *1982 Bratislava
                                <br/>
                                štúdium architektúry na STU – Bratislava
                                </p>
                            </div>

                            <div class="team-others-padding">
                                Ing. Barbora Šimečková
                                <br />
                                <p class="team-others-p team-others-p-birth">
                                *1997 Bratislava
                                <br />
                                štúdium PS a architektúry na STU – Bratislava
                                </p>
                            </div>

                            <div class="team-others-padding">
                                štud. Matej Kýška
                                <br />
                                <p class="team-others-p team-others-p-birth">
                                *2001 Bratislava
                                <br />
                                štúdium PS na VUT – Brno
                                </p>
                            </div>

                            <div class="team-others-padding">
                                štud. Lukáš Stas
                                <br />
                                <p class="team-others-p team-others-p-birth">
                                *2001 Trstená
                                <br />
                                štúdium PS a architektúry na STU – Bratislava
                                </p>
                            </div>
                        </p>
                    </div>

                    <div class="col">
                        <h5 className="team-others-heading">Praktikanti</h5>
                        <hr className="team-right-col"/> 
                        <p className='team-others-p'>
                                Bc. Barbora Šimečková	
                                    <p class="team-others-p team-others-p-birth">
                                    2019
                                    </p>

                            <div class="team-others-padding">
                            Bc. Martina Rajťúková	
                                <p class="team-others-p team-others-p-birth">
                                2018
                                </p>
                            </div>

                            <div class="team-others-padding">
                            Ing. Janka Jurkovičová
                                <p class="team-others-p team-others-p-birth">
                                2017 - 2018
                                </p>
                            </div>

                            <div class="team-others-padding">
                            Bc. Broňa Krúpová 		
                                <p class="team-others-p team-others-p-birth">
                                2015 - 2016
                                </p>
                            </div>

                            <div class="team-others-padding">
                            Bc. Martin Kostrej		
                                <p class="team-others-p team-others-p-birth">
                                2014
                                </p>
                            </div>

                            <div class="team-others-padding">
                            Bc. Michaela Rečlová		
                                <p class="team-others-p team-others-p-birth">
                                2013
                                </p>
                            </div>
                        </p>
                    </div>
            </div>
            

            <div className=" team-others">
                <div class="row">
                    <div class="col">
                        <h5 className="team-others-heading team-others-heading-left-col">Bývalí kolegovia</h5>
                        <hr className="team-left-col"/> 
                        <p className='team-others-p team-others-p-left-col'>
                            Ing. arch. Peter Hromják 	
                            <p class="team-others-p team-others-p-birth">
                            2009 – 2012
                            </p>

                            <div class="team-others-padding">
                                Ing. arch. Beáta Jacková	
                                <p class="team-others-p team-others-p-birth">
                                2012 – 2015
                                </p>
                            </div>

                            <div class="team-others-padding">
                                Ing. Eva Takácsová 		
                                <p class="team-others-p team-others-p-birth">
                                2012 – 2014, 2018 - 2022
                                </p>
                            </div>

                            <div class="team-others-padding">
                                Ing. Andrej Molnár	
                                <p class="team-others-p team-others-p-birth">
                                2012 – 2014, 2022 - 2023
                                </p>
                            </div>
                            
                            <div class="team-others-padding">
                                Ing. arch. Viktor Lampert
                                <p class="team-others-p team-others-p-birth">
                                2014 – 2021      
                                </p> 
                            </div>                           
                        </p>
                    </div>

                    <div class="col">
                        <h5 className="team-others-heading">Externí kolegovia</h5>
                        <hr className="team-right-col"/> 
                        <p className='team-others-p'>
                            Ing. Monika Havetová
                            <br />
                            Ing. Matúš Repašský
                            <br />
                            Ing. arch. Beáta Jacková
                        </p>
                    </div>
                </div>
            
                </div>
            </div>
        </Fade>
        </Waypoint>
        <Footer />
      </div>
    );
  }
  
  export default Team;

/*** End of Team.js ***/
