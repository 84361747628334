import './App.css';
import NavBar from './shared/NavBar';
import Footer from './shared/Footer'
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component, useEffect }  from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import AboutPage from './pages/about/About';
import Contact from './pages/contact/Contact';
import Projects from './pages/projects/Projects';
import Team from './pages/team/Team';
import Legal from './pages/legal/Legal';
import Fitinn from './pages/projects/detaiPages/Fitinn'
import Inkubator from './pages/projects/detaiPages/Inkubator'
import RajeckeTeplice from './pages/projects/detaiPages/RajeckeTeplice'
import Zelenka from './pages/projects/detaiPages/Zelenka'
import Cunovo from './pages/projects/detaiPages/Cunovo'
import RodinnyDom1 from './pages/projects/detaiPages/RodinnyDom1'
import RodinnyDom2 from './pages/projects/detaiPages/RodinnyDom2'
import BytovyDomVieden from './pages/projects/detaiPages/BytovyDomVieden'
import PolusTowers from './pages/projects/detaiPages/PolusTowers'
import Dunajska54 from './pages/projects/detaiPages/Dunajska54'

function App() { 
  useEffect(() => {
    if (window.location.pathname === "/kontakt") {
      document.querySelector(".wrapper").style.paddingBottom = "70px";
    } else if (window.location.pathname === "/pravne-informacie") {
      document.querySelector(".wrapper").style.paddingBottom = "0px";
    }
  }, []);

  return (
    <div className="App">
      <div className="wrapper">
      <Router>
        <NavBar/>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/o-nas' element={<AboutPage/>} />
          <Route path='/kontakt' element={<Contact/>} />
          <Route path='/projekty' element={<Projects/>} />
          <Route path='/nas-team' element={<Team/>} />
          <Route path='/pravne-informacie' element={<Legal/>} />
          <Route path='/projekty/fitinn' element={<Fitinn/>} />
          <Route path='/projekty/inkubator' element={<Inkubator/>} />
          <Route path='/projekty/rajecke-teplice' element={<RajeckeTeplice/>} />
          <Route path='/projekty/zelenka' element={<Zelenka/>} />
          <Route path='/projekty/cunovo' element={<Cunovo/>} />
          <Route path='/projekty/rodinny-dom-1' element={<RodinnyDom1/>} />
          <Route path='/projekty/rodinny-dom-2' element={<RodinnyDom2/>} />
          <Route path='/projekty/bytovy-dom-vieden' element={<BytovyDomVieden/>} />
          <Route path='/projekty/polus-towers' element={<PolusTowers/>} />
          <Route path='/projekty/dunajska-54' element={<Dunajska54/>} />
        </Routes>
      </Router>
      </div>
    </div>
  );
}

export default App;
