/**
 * @file Interior.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of interior projects page component.
 */

import React, {useEffect} from 'react';
import "./projects-list.css"
import Fitinn from '../../images/projects/fitinnt.jpg';
import PolusTowers from '../../images/projects/polust.jpg';
import Dunajska54 from '../../images/projects/dunajskat.jpg';

function Interior() { 
  useEffect(() => {
    function handleResize() {
      const textHeight = document.querySelector('hr').offsetWidth;
      let images = document.querySelectorAll('.hover-blur a');
      
      const mediaQuery = window.matchMedia("(min-width: 900px)");
        
      if (mediaQuery.matches) {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight/3 -16+ 'px';
        }
      } else {
        for(let i = 0; i < images.length; i++) {
            images[i].style.width = textHeight + 'px';
        }
      }
}

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
}, []);

return (
  <div>
    <div className="row projects-list-img-div">
        <a className="projects-list-a" href="/projekty/dunajska-54">
            <div className="hover-blur">
            <a href="/projekty/dunajska-54" title="">
                <img src={Dunajska54} alt="" className="img-fluid"/>
                <h2><span className="text-white">Dunajská 54</span></h2>
            </a>
            </div>
        </a>

        <a className="projects-list-a" href="/projekty/polus-towers">
            <div className="hover-blur">
            <a href="/projekty/polus-towers" title="">
                <img src={PolusTowers} alt="" className="img-fluid"/>
                <h2><span className="text-white">Polus Towers</span></h2>
            </a>
            </div>
        </a>

        <a className="projects-list-a" href="/projekty/fitinn">
            <div className="hover-blur">
            <a class="test" href="/projekty/fitinn" title="">
                <img src={Fitinn} alt="" className="img-fluid"/>
                <h2><span className="text-white">Fitinn</span></h2>
            </a>
            </div>
        </a>

    </div>
  </div>  
);
  }
  export default Interior;

/*** End of Interior.js ***/