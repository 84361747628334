/**
 * @file NavBar.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of navigation bar
 */

import React from 'react'
import logo from '../images/logo.png'; 
import "./navbar.css"

import { Link} from "react-router-dom";

export default function NavBar() {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-fixed-top bg-white">
        <a className="navbar-brand" href="/">
            <img src={logo} width="45" height="45" alt="" className="navbar-image"/>
            <span className='navbar-logo-text'>kyska</span><span className='navbar-logo-text-arch'> architects</span>
        </a>
        <button className="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse .links" id="navbarNavAltMarkup">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className={"nav-link " + ((!window.location.href.includes("o-nas") && !window.location.href.includes("projekty") && !window.location.href.includes("nas-team") && !window.location.href.includes("kontakt") && !window.location.href.includes("pravne-informacie"))  ? "active" : "")}   href="/">Domov</a>
                </li>
                <li className="nav-item">
                        <a className={"nav-link " + (window.location.href.includes("o-nas") ? "active" : "")} href="/o-nas">O nás</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (window.location.href.includes("projekty") ? "active" : "")}  href="/projekty">Projekty</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (window.location.href.includes("nas-team") ? "active" : "")}  href="/nas-team">Náš team</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (window.location.href.includes("kontakt") ? "active" : "")} href="/kontakt">Kontakt</a>
                </li>
            </ul>
        </div>
    </nav>

    
  )
}

/*** End of NavBar.js ***/