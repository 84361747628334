/**
 * @file Gif.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of randomly choosed gifs at home page.
 */

import React from 'react'
import video from '../../images/videoh264.mp4'

export default function Gif() {
  return (

  <video class="w-100" autoPlay loop muted playsInline>
  <source src={video} type="video/mp4" />
  </video>

    )
}

/*** End of Gif.js ***/