import React from 'react';
import Atp from '../../images/clients/ATP.png';
import Danicon from '../../images/clients/DANICON.png';
import Fitinn from '../../images/clients/FITINN.png';
import Immofinanz from '../../images/clients/IMMOFINANZ.jpeg';
import Teichmann from '../../images/clients/TEICHMANN.png';
import Yit from '../../images/clients/YIT.png';
import Vermont from '../../images/clients/VERMONT.png';
import Vivo from '../../images/clients/VIVO.png';
import "./partners.css"

function Partners() { 
    return (
        <div className="row partners-img-div">
            <img src={Atp} className="partners-img img-fluid"/>

            <img src={Danicon} className="partners-img img-fluid"/>

            <img src={Fitinn} className="partners-img img-fluid"/>

            <img src={Immofinanz} className="partners-img img-fluid" />

            <img src={Teichmann} className="partners-img img-fluid"/>

            <img src={Yit} className="partners-img img-fluid"/>

            <img src={Vermont} className="partners-img img-fluid"/>

            <img src={Vivo} className="partners-img img-fluid"/>
        </div>
    );
  }
     
  export default Partners;
