/**
 * @file All.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of all projects page component.
 */

import React, {useEffect} from 'react';
import "./projects-list.css"
import Fitinn from '../../images/projects/fitinnt.jpg';
import Cunovo from '../../images/projects/cunovo.jpg';
import Zelenka from '../../images/projects/zelenka.jpg';
import RajeckeTeplice from '../../images/projects/rajecket.jpg';
import BytovyDomVieden from '../../images/projects/bytovydomt.jpg';
import RodinnyDom2 from '../../images/projects/rodinnydom2t.jpg';
import Inkubator from '../../images/projects/inkubatort.jpg';
import Dunajska54 from '../../images/projects/dunajskat.jpg';
import RodinnyDom1 from '../../images/projects/rodinnydom1t.jpg';
import PolusTowers from '../../images/projects/polust.jpg';

function All() { 
    useEffect(() => {
        function handleResize() {
          const textHeight = document.querySelector('hr').offsetWidth;
          let images = document.querySelectorAll('.hover-blur a');
          
          const mediaQuery = window.matchMedia("(min-width: 900px)");
            
          if (mediaQuery.matches) {
            for(let i = 0; i < images.length; i++) {
                images[i].style.width = textHeight/3 -16+ 'px';
            }
          } else {
            for(let i = 0; i < images.length; i++) {
                images[i].style.width = textHeight + 'px';
            }
          }
    }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
      <div>
        <div className="row projects-list-img-div">

            <a className="projects-list-a" href="/projekty/cunovo">
                <div className="hover-blur">
                <a href="/projekty/cunovo" title="">
                    <img src={Cunovo} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Čuňovo</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/zelenka">
                <div className="hover-blur">
                <a href="/projekty/zelenka" title="">
                    <img src={Zelenka} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Zelenka</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/rajecke-teplice">
                <div className="hover-blur">
                <a href="/projekty/rajecke-teplice" title="">
                    <img src={RajeckeTeplice} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Rajecké teplice</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/bytovy-dom-vieden">
                <div className="hover-blur">
                <a href="/projekty/bytovy-dom-vieden" title="">
                    <img src={BytovyDomVieden} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Bytový dom - Viedeň</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/rodinny-dom-2">
                <div className="hover-blur">
                <a href="/projekty/rodinny-dom-2" title="">
                    <img src={RodinnyDom2} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Rodinný dom II</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/inkubator">
                <div className="hover-blur">
                <a href="/projekty/inkubator" title="">
                    <img src={Inkubator} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Inkubátor</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/dunajska-54">
                <div className="hover-blur">
                <a href="/projekty/dunajska-54" title="">
                    <img src={Dunajska54} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Dunajská 54</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/rodinny-dom-1">
                <div className="hover-blur">
                <a href="/projekty/rodinny-dom-1" title="">
                    <img src={RodinnyDom1} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Rodinný dom I</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/polus-towers">
                <div className="hover-blur">
                <a href="/projekty/polus-towers" title="">
                    <img src={PolusTowers} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Polus Towers</span></h2>
                </a>
                </div>
            </a>

            <a className="projects-list-a" href="/projekty/fitinn">
                <div className="hover-blur">
                <a class="test" href="/projekty/fitinn" title="">
                    <img src={Fitinn} alt="" className="img-fluid"/>
                    <h2><span className="text-white">Fitinn</span></h2>
                </a>
                </div>
            </a>


        </div>


      </div>

      
    );
  }
  export default All;

/*** End of All.js ***/