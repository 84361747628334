/**
 * @file Projects.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of main projects page component.
 */

import React, {useEffect, useState} from 'react';
import Footer from '../../shared/Footer';
import "./projects.css"
import { Fade } from 'react-reveal';
import { Waypoint } from 'react-waypoint';
import All from "./All";
import Architecture from './Architecture';
import Interior from './Interior';

function Projects() { 
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };

    useEffect(() => {
        document.title = "Projekty - kyska.sk";
        function handleResize() {
                const  navbarHeight = document.querySelector('.navbar').offsetHeight;
                let heading = document.querySelector('.top-heading');
                if (heading) {
                    heading.style.paddingTop = navbarHeight +'px';
                }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
      <div>
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
    
        <h3 className="top-heading"></h3>

        <div className='projects-menu-container'>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="true">Všetko</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="pills-architecture-tab" data-toggle="pill" href="#pills-architecture" role="tab" aria-controls="pills-architecture" aria-selected="false">Architektúra</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="pills-interior-tab" data-toggle="pill" href="#pills-interior" role="tab" aria-controls="pills-interior" aria-selected="false">Interiér</a>
            </li>
        </ul>
        </div>
        
        <hr className="projects-hr"/>

        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <All />
            </div>
            
            <div className="tab-pane fade" id="pills-architecture" role="tabpanel" aria-labelledby="pills-architecture-tab">
                <Architecture />
            </div>
            
            <div className="tab-pane fade" id="pills-interior" role="tabpanel" aria-labelledby="pills-interior-tab">
                <Interior />
            </div>
        </div>

        </Fade>
        </Waypoint>

        <Footer />
      </div>
    );
  }
  export default Projects;

/*** End of Projects.js ***/