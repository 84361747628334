/**
 * @file News.js
 * @author Martin Kubička (martinkubicka22@gmail.com)
 * @date 25.01.2023
 * 
 * @brief Declaration of news component at home page.
 */

import React, {useEffect, useState} from 'react'
import "./news.css"
import news1 from "../../images/news1.jpg"
import news2 from "../../images/news2.jpg"
import { Waypoint } from 'react-waypoint';
import Fade from 'react-reveal/Fade';

export default function News() {
    const [animationTriggered, setAnimationTriggered] = useState(false);

    const handleWaypointEnter = () => {
      setAnimationTriggered(true);
    };


    useEffect(() => {
        function handleResize() {
          const textHeight = document.querySelector('.about-container').offsetWidth;
          let images = document.querySelectorAll('.news img');
          
          const mediaQuery = window.matchMedia("(min-width: 900px)");
            if (mediaQuery.matches) {
                for(let i = 0; i < images.length; i++) {
                    images[i].style.width = textHeight - 400 + 'px';
                }
            } else {
                for(let i = 0; i < images.length; i++) {
                    images[i].style.width = textHeight - 40 + 'px';
                }
            }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);

  return (
    <div class="news">
        <Waypoint onEnter={handleWaypointEnter}>
        <Fade duration={1000}>
        <h3>Novinky</h3>

        <p className="date-news">3.6.2023</p>
        <img src={news2}></img>
        <p className="news-text">Prehĺbili sme v posledných rokoch spoluprácu s viedenským ateliérom MAG na viacerých projektoch mestských víl. Pre ilustráciu uvádzame jednu z nich Vila - Baumeistergasse.</p>

        <hr />

        <p className="date-news">1.6.2023</p>
        <img src={news1}></img>
        <p className="news-text">V pátraní po autorovi architektonických návrhov - originálov z roku 1878 v našom vlastníctve, sme s veľkým prekvapením a potešením zistili, že autorom je významný architekt Imrich Steindl (maďarsky Steindl Imre; * 29. október 1839 – † 31. august 1902, Budapešť, Maďarsko). Korunou jeho tvorby je budapeštiansky parlament, ktorý je svetoznámy a vďaka nemu sa nikdy nezabudne na jeho staviteľa Steindla.</p>

        </Fade>
        </Waypoint>
    </div>
  )
}

/*** End of News.js ***/